

let Azimut103 = [
  {
    id: 464,
    name: "103 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/azimut101.jpg",
  },
  {
    id: 465,
    name: "103 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/azimut102.jpg",
  },
  {
    id: 466,
    name: "103 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/azimut103.jpg",
  },
  {
    id: 467,
    name: "103 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/azimut104.jpg",
  },
  {
    id: 468,
    name: "103 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/azimut105.jpg",
  },
  {
    id: 469,
    name: "103 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/azimut106.jpg",
  },
  {
    id: 470,
    name: "103 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/azimut107.jpg",
  },
];

export default Azimut103;