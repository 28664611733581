

let BlockchainManor = [
    {
        id: 1,
        name: "Guest Bathroom with Sink and Shower",
        category: "Blockchain Manor",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bathroom.jpg",
      },
      {
        id: 2,
        name: "Guest Bathroom with Two Sinks and Shower",
        category: "Blockchain Manor",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bathroom2.jpg",
      },
    //   {
    //     id: 3,
    //     name: "Guest Bathroom with Two Sinks and Shower side view",
    //     category: "Blockchain Manor",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bathroom3.jpg",
    //   },
      {
        id: 4,
        name: "Guest Bathroom with long sink and open shower",
        category: "Blockchain Manor",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bathroom4.jpg",
      },
      {
        id: 5,
        name: "Guest Bathroom with long sink and open shower side view",
        category: "Blockchain Manor",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bathroom5.jpg",
      },
    //   {
    //     id: 6,
    //     name: "Guest Bathroom with single Sink and Shower",
    //     category: "Blockchain Manor",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bathroom6.jpg",
    //   },
      {
        id: 7,
        name: "Silver Bedroom",
        category: "Blockchain Manor",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom.jpg",
      },
      {
        id: 8,
        name: "Silver Bedroom side view",
        category: "Blockchain Manor",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom2.jpg",
      },
    //   {
    //     id: 9,
    //     name: "Silver Bedroom front view",
    //     category: "Blockchain Manor",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom3.jpg",
    //   },
      {
        id: 10,
        name: "Bedroom with leather couch",
        category: "Blockchain Manor",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom4.jpg",
      },
      {
        id: 11,
        name: "Bedroom with leather couch front view",
        category: "Blockchain Manor",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom5.jpg",
      },
    //   {
    //     id: 12,
    //     name: "Bedroom with leather couch back view",
    //     category: "Blockchain Manor",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom6.jpg",
    //   },
    //   {
    //     id: 13,
    //     name: "Bedroom with leather couch angle view",
    //     category: "Blockchain Manor",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom7.jpg",
    //   },
      {
        id: 14,
        name: "Bedroom with Queen-sized bed",
        category: "Blockchain Manor",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom8.jpg",
      },
      {
        id: 15,
        name: "Bedroom with Queen-sized bed front view",
        category: "Blockchain Manor",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom9.jpg",
      },
    //   {
    //     id: 16,
    //     name: "Bedroom with Queen-sized bed angle view",
    //     category: "Blockchain Manor",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom10.jpg",
    //   },
    // {
    //     id: 17,
    //     name: "Bedroom with twin sized bed and couch",
    //     category: "blockchain",
    //     image:  "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom11.jpg",
    // },
    {
        id: 18,
        name: "Bedroom with twin sized bed and couch side view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom12.jpg",
    },
    {
        id: 19,
        name: "Bedroom with twin sized bed with wall paper",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom13.jpg",
    },
    {
        id: 20,
        name: "Bedroom with twin sized bed with wall paper side view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom14.jpg",
    },
    {
        id: 21,
        name: "Bedroom with twin sized bed with wall paper front view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+bedroom15.jpg",
    },
    {
        id: 22,
        name: "Family room with open space",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+den1.jpg",
    },
    {
        id: 23,
        name: "Family room with open space side view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+den2.jpg",
    },
    {
        id: 24,
        name: "Family room with kitchen view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+dinningroom1.jpg",
    },
    // {
    //     id: 25,
    //     name: "Family room with only kitchen view",
    //     category: "blockchain",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+dinningroom2.jpg",
    // },
    {
        id: 26,
        name: "Driveway with fireplace view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+Driveway.jpg",
    },
    {
        id: 27,
        name: "Driveway with stairs view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+driveway2.jpg",
    },
    // {
    //     id: 28,
    //     name: "Driveway with front house view",
    //     category: "blockchain",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+driveway3.jpg",
    // },
    // {
    //     id: 29,
    //     name: "Table with family room view",
    //     category: "blockchain",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+eating1.jpg",
    // },
    {
        id: 30,
        name: "Game room with soccer table view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+gameroom.jpg",
    },
    // {
    //     id: 31,
    //     name: "Game room with pool table and soccer table view",
    //     category: "blockchain",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+gameroom2.jpg",
    // },
    {
        id: 32,
        name: "Game room with pool table view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+gameroom3.jpg",
    },
    {
        id: 33,
        name: "Hallway view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+hallway.jpg",
    },
    {
        id: 34,
        name: "Bar view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+kitchen1.jpg",
    },
    // {
    //     id: 35,
    //     name: "Bar back view",
    //     category: "blockchain",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+kitchen2.jpg",
    // },
    {
        id: 36,
        name: "Kitchen with sink view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+kitchen3.jpg",
    },
    {
        id: 37,
        name: "Kitchen with bar view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+kitchen4.jpg",
    },
    {
        id: 38,
        name: "Kitchen with oven view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+kitchen5.jpg",
    },
    // {
    //     id: 39,
    //     name: "Kitchen with angle view",
    //     category: "blockchain",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+kitchen6.jpg",
    // },
    {
        id: 40,
        name: "Living room with bar",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+livingroom1.jpg",
    },
    {
        id: 41,
        name: "Living room with bar side view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+livingroom2.jpg",
    },
    {
        id: 42,
        name: "Movie theatre back view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+movietheater.jpg",
    },
    {
        id: 43,
        name: "Movie theatre side view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+Movietheater2.jpg",
    },
    // {
    //     id: 44,
    //     name: "Movie theatre front view ",
    //     category: "blockchain",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+Movietheater3.jpg",
    // },
    // {
    //     id: 45,
    //     name: "Movie theatre back view ",
    //     category: "blockchain",
    //     image: "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+Movietheater2.jpg",
    // },
    {
        id: 46,
        name: "Outside grill view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside1.jpg",
    },
    // {
    //     id: 47,
    //     name: "Couch pool view ",
    //     category: "blockchain",
    //     image: "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside2.jpg",
    // },
    {
        id: 48,
        name: "Pool couch angle view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside3.jpg",
    },
    // {
    //     id: 49,
    //     name: "Pool back view ",
    //     category: "blockchain",
    //     image: "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside4.jpg",
    // },
    {
        id: 50,
        name: "Pool back view with statue",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside5.jpg",
    },
    {
        id: 51,
        name: "Tiki hut",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside6.jpg",
    },
    {
        id: 52,
        name: "Inside Tiki hut ",
        category: "blockchain",
        image:  "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside7.jpg",
    },
    // {
    //     id: 53,
    //     name: "Tikki hut back view ",
    //     category: "blockchain",
    //     image: "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside8.jpg",
    // },
    {
        id: 54,
        name: "Hot tub day time view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside9.jpg",
    },
    // {
    //     id: 55,
    //     name: "Pool daytime view ",
    //     category: "blockchain",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside10.jpg",
    // },
    {
        id: 56,
        name: "Daytime statue view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside11.jpg",
    },
    // {
    //     id: 57,
    //     name: "Tiki hut angle view",
    //     category: "blockchain",
    //     image: "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside12.jpg",
    // },
    // {
    //     id: 58,
    //     name: "Hot tub side view ",
    //     category: "blockchain",
    //     image: "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside13.jpg",
    // },
    {
        id: 59,
        name: "Outside pool beach chair view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside14.jpg",
    },
    // {
    //     id: 60,
    //     name: "Tiki hut with hot tub during the day ",
    //     category: "blockchain",
    //     image: "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside15.jpg",
    // },
    {
        id: 61,
        name: "Pool Outside Daytime view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside20.jpg",
    },
    {
        id: 62,
        name: "Pool and chair Daytime side view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside21.jpg",
    },
    {
        id: 63,
        name: "Tiki hut tree view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside22.jpg" ,
    },
    {
        id: 64,
        name: "Tiki hut grass view",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside23.jpg",
    },
    {
        id: 65,
        name: "Tree house back view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside24.jpg",
    },
    // {
    //     id: 66,
    //     name: "Tiki hut night view ",
    //     category: "blockchain",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside30.jpg",
    // },
    {
        id: 67,
        name: "Tiki hut inside night view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside31.jpg",
    },
    // {
    //     id: 68,
    //     name: "Tiki hut inside table view ",
    //     category: "blockchain",
    //     image: "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside33.jpg",
    // },
    {
        id: 69,
        name: "Night time house and tiki hut view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside34.jpg",
    },
    {
        id: 70,
        name: "Night time tiki hut view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside37.jpg",
    },
    {
        id: 71,
        name: "Night time patio view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside40.jpg",
    },
    {
        id: 72,
        name: "Daytime outside sky view ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside50.jpg",
    },
    // {
    //     id: 73,
    //     name: "Daytime outside sky view 2 ",
    //     category: "blockchain",
    //     image: "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside51.jpeg",
    // },
    // {
    //     id: 74,
    //     name: "Daytime outside sky view 3 ",
    //     category: "blockchain",
    //     image: "./https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+outside58.jpeg",
    // },
    {
        id: 75,
        name: "Night pool with statue Gorilla ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+pool.jpeg",
    },
    {
        id: 76,
        name: "Open shower side view only ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+shower1.jpg",
    },
    {
        id: 77,
        name: "Single sink view only ",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+sink1.jpg",
    },
    // {
    //     id: 78,
    //     name: "Stair view only ",
    //     category: "blockchain",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/Blockchain+stairs1.jpg" ,
    // },
];

export default BlockchainManor;