
let Elleven = [


  {
    id: 495,
    name: "11 ",
    category: "club",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven3.jpg",
  },
  {
    id: 503,
    name: "11 ",
    category: "club",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven11.jpg",
  },
    {
    id: 496,
    name: "11 ",
    category: "club",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven4.jpeg",
  },
  //   {
  //   id: 497,
  //   name: "11 ",
  //   category: "club",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven5.mp4",
  //   isVideo: true,
  // },
    {
    id: 498,
    name: "11 ",
    category: "club",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven6.jpg",
  },
    {
    id: 499,
    name: "11 ",
    category: "club",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven7.jpg",
  },
    {
    id: 500,
    name: "11 ",
    category: "club",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven8.jpg",
  },
    {
    id: 501,
    name: "11 ",
    category: "club",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven9.jpg",
  },
    {
    id: 502,
    name: "11 ",
    category: "club",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven10.jpg",
  },
  
    {
    id: 504,
    name: "11 ",
    category: "club",
    image:"https://globalasset1.s3.us-east-2.amazonaws.com/elleven12.jpg",
  },
  {
    id: 494,
    name: "11 ",
    category: "club",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven1.jpg",
  },
];

export default Elleven