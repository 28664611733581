


let Leopard = [


{
    id: 300,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard1.jpg",
  },
  {
    id: 301,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard2.jpg",
  },
  {
    id: 302,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard3.jpg",
  },
  {
    id: 303,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard4.jpg",
  },
  {
    id: 304,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard5.jpg",
  },
  {
    id: 305,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard6.jpg",
  },
  {
    id: 306,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard7.jpg",
  },
  {
    id: 307,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard8.jpg",
  },
  {
    id: 308,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard9.jpg",
  },
  {
    id: 309,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard10.jpg",
  },
  {
    id: 310,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard11.jpg",
  },
  {
    id: 311,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard12.jpg",
  },
  {
    id: 312,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard13.jpg",
  },
  {
    id: 313,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/88leopard14.jpg",
  },
];

export default Leopard;