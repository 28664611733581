

const Azimut = [
  {
    id: 287,
    name: "90azimut1",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut1.jpg",
  },
  // {
  //   id: 288,
  //   name: "90azimut2 video",
  //   category: "Yacht",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut2.mp4",
  //   isVideo: true,
  // },
  {
    id: 289,
    name: "90azimut3",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut3.jpg",
  },
  {
    id: 290,
    name: "90azimut4",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut4.jpg",
  },
  {
    id: 291,
    name: "90azimut5",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut5.jpg",
  },
  {
    id: 292,
    name: "90azimut6",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut6.jpg",
  },
  {
    id: 293,
    name: "90azimut7",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut7.jpg",
  },
  {
    id: 294,
    name: "90azimut8",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut8.jpg",
  },
  {
    id: 295,
    name: "90azimut9",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut9.jpg",
  },
  {
    id: 296,
    name: "90azimut10",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut10.jpg",
  },
  {
    id: 297,
    name: "90azimut11",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut11.jpg",
  },
  {
    id: 298,
    name: "90azimut12",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/90azimut12.jpg",
  },
];

export default Azimut;