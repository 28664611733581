


let SeaSpice = [
    {
        id: 523,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice8.jpg",
        
      },
    {
        id: 520,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice2.jpg",
        
      },
     
      {
        id: 524,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice10.jpg",
        
      },
      // {
      //   id: 531,
      //   name: "sea ",
      //   category: "food",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice18.mp4",
      //   isVideo: true,
        
      // },
      {
        id: 525,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice12.jpg",
        
      },
      {
        id: 526,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice13.jpg",
        
      },
      {
        id: 521,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice4.jpg",
        
      },
      {
        id: 522,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice6.jpg",
        
      },
      
      {
        id: 527,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice14.jpg",
        
      },
      {
        id: 528,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice15.jpg",
        
      },
      {
        id: 529,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice16.jpg",
        
      },
      {
        id: 530,
        name: "sea ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/seaspice17.jpg",
        
      },
     
      
    
];
export default SeaSpice