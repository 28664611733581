


let MrJones = [
      
    {
        id: 484,
        name: "103 ",
        category: "hotel",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/jonesgirls3.jpg",
      },
      // {
      //   id: 485,
      //   name: "103 ",
      //   category: "hotel",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/jonestydolla.mp4",
      //   isVideo: true,
      // },
      {
        id: 488,
        name: "103 ",
        category: "hotel",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/jonesgirls4.jpg",
      },
      // {
      //   id: 489,
      //   name: "103 ",
      //   category: "hotel",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/jonesgirls2.mp4",
      //   isVideo: true,
      // },
     
      {
        id: 491,
        name: "103 ",
        category: "hotel",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/joneskodak.jpg",
      },
      
      // {
      //   id: 487,
      //   name: "103 ",
      //   category: "hotel",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/joneslatin.mp4",
      //   isVideo: true,
      // },
      {
        id: 490,
        name: "103 ",
        category: "hotel",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/jones42.jpg",
      },
      // {
      //   id: 486,
      //   name: "103 ",
      //   category: "hotel",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/jonesgirls1.mp4",
      //   isVideo : true
      // },
     
     
      {
        id: 492,
        name: "103 ",
        category: "hotel",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/jonesski.jpg",
      },
      {
        id: 493,
        name: "103 ",
        category: "hotel",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/jonesgirls5.jpg",
      },

]

export default MrJones