
let Vendome = [

    {
      id: 237,
      name: "Birthday cake",
      category: "Vendome",
      image: "https://globalasset1.s3.us-east-2.amazonaws.com/VENDOMECAKE.jpg",
    },
    // {
    //   id: 238,
    //   name: "50cent",
    //   category: "Vendome",
    //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/VENDOME50CENT.mp4",
    //   isVideo: true, // Add a flag to distinguish between video and image
    // },
    {
        id: 242,
        name: "Girls 2",
        category: "Vendome",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/VENDOMEGIRLS2.jpg",
      },

    
    // {
    //   id: 239,
    //   name: "Bryson Tiller",
    //   category: "Vendome",
    //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/VENDOMEBRYSON.mp4",
    //   isVideo: true, // Add a flag to distinguish between video and image
    // },
    {
        id: 241,
        name: "Girls 1",
        category: "Vendome",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/VENDOMEGIRLS.jpeg",
      },

    // {
    //   id: 240,
    //   name: "future",
    //   category: "Vendome",
    //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/VENDOMEfuture.mp4",
    //   isVideo: true, // Add a flag to distinguish between video and image
    // },
  
  
    {
      id: 243,
      name: "Girls 3",
      category: "Vendome",
      image: "https://globalasset1.s3.us-east-2.amazonaws.com/VENDOMEGIRLS3.jpg",
    },
    {
      id: 244,
      name: "Girls 4",
      category: "Vendome",
      image: "https://globalasset1.s3.us-east-2.amazonaws.com/VENDOMEGIRLS4.jpeg",
    },
    {
      id: 245,
      name: "ace of spade",
      category: "Vendome",
      image: "https://globalasset1.s3.us-east-2.amazonaws.com/VENDOMESPADE.jpg",
    },
  
];

export default Vendome
