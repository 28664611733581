
let Leopard_105 = [


{
    id: 373,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard1.jpg",
  },
  {
    id: 374,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard2.jpeg",
  },
  {
    id: 375,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard3.jpg",
  },
  {
    id: 376,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard4.jpg",
  },
  {
    id: 377,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard5.jpg",
  },
  {
    id: 378,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard6.jpg",
  },
  {
    id: 378,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard7.jpg",
  },
  {
    id: 379,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard8.jpg",
  },
  {
    id: 380,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard9.jpg",
  },
  {
    id: 381,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard10.jpg",
  },
  {
    id: 382,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard11.jpg",
  },
  {
    id: 383,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard12.jpg",
  },
  {
    id: 384,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard13.jpg",
  },
  {
    id: 385,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard14.jpg",
  },
  {
    id: 386,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard15.jpg",
  },
  {
    id: 387,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard16.jpg",
  },
  {
    id: 388,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard17.jpg",
  },
  {
    id: 389,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard18.jpg",
  },
  {
    id: 390,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard19.jpg",
  },
  {
    id: 391,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard20.jpg",
  },
  {
    id: 392,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard21.jpg",
  },
  {
    id: 393,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard22.jpg",
  },
  {
    id: 394,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard23.jpg",
  },
  {
    id: 395,
    name: "105 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/105_+leopard24.jpg",
  },
];
export default Leopard_105;
