


let OneHotel = [
  // dEPLOY
  {
    id: 471,
    name: "one",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel1.webp",
  },
  {
    id: 472,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel2.webp",
  },
  {
    id: 473,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel3.webp",
  },
  {
    id: 474,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel4.webp",
  },
  {
    id: 475,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel5.webp",
  },
  {
    id: 477,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel7.webp",
  },
  {
    id: 478,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel8.webp",
  },
  {
    id: 479,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel9.webp",
  },
  {
    id: 480,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel10.webp",
  },
  {
    id: 481,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel11.webp",
  },
  {
    id: 482,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel12.webp",
  },
  {
    id: 483,
    name: "103",
    category: "hotel",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/onehotel13.webp",
  },
];

export default OneHotel;