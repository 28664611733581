

let Sprinter = [
    {
        id: 270,
        name: "Sprinter1 ",
        category: "sprinter",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/sprinterfrontview.jpg",
      },
      {
        id: 270,
        name: "Sprinter2 ",
        category: "sprinter",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/sprinterinside2.jpg",
      },
    
      // {
      //   id: 271,
      //   name: "Sprinter2 ",
      //   category: "sprinter",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/sprinterinside.mp4",
      //   isVideo: true,
      // },

];

export default Sprinter;
