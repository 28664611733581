


let Betsy = [
    // {
    //     id: 423,
    //     name: "70 ",
    //     category: "Yacht",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy27.mp4",
    //     isVideo: true,  // Add a flag to distinguish between video and image
    //   },
    // {
    //     id: 415,
    //     name: "70 ",
    //     category: "Yacht",
    //     image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy1.jpg",
    //   },
      {
        id: 416,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy2.jpg",
      },
      // {
      //   id: 417,
      //   name: "70 ",
      //   category: "Yacht",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy3.jpg",
      // },
      {
        id: 418,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy4.jpg",
      },
      {
        id: 419,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy7.jpg",
      },
      {
        id: 420,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy10.jpg",
      },
      {
        id: 421,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy12.jpg",
      },
      {
        id: 422,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy21.png",
      },
      
      {
        id: 424,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy30.JPG",
      },
      {
        id: 425,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy31.JPG",
      },
      // {
      //   id: 426,
      //   name: "70 ",
      //   category: "Yacht",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy12.jpg",
      // },
      // {
      //   id: 427,
      //   name: "70 ",
      //   category: "Yacht",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy34.JPG",
      // },
      // {
      //   id: 428,
      //   name: "70 ",
      //   category: "Yacht",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy37.JPG",
      // },
      {
        id: 429,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy39.JPG",
      },
      // {
      //   id: 430,
      //   name: "70 ",
      //   category: "Yacht",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy40.JPG",
      // },
      {
        id: 431,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy41.JPG",
      },
      {
        id: 432,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy45.jpg",
      },
      // {
      //   id: 433,
      //   name: "70 ",
      //   category: "Yacht",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy48.jpg",
      // },
      {
        id: 434,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy49.jpg",
      },
      // {
      //   id: 435,
      //   name: "70 ",
      //   category: "Yacht",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy50.jpg",
      // },
      {
        id: 436,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy51.jpg",
      },
      // {
      //   id: 437,
      //   name: "70 ",
      //   category: "Yacht",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy55.jpg",
      // },
      {
        id: 438,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/betsy56.jpg",
      },
];
export default Betsy;