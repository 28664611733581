
let VillaLuna =[

{
    id: 152,
    name: " Backyard night view with pool and chairs  ",
    category: "Luna", 
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Luna1.jpg" ,  

},

{
    id: 153,
    name: " Backyard night view with pool and chairs side view ", 
    category: "Luna", 
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Luna2.jpg" ,  

},

{
    id: 154,
    name: " Night view with pool  ", 
    category: "Luna", 
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Luna4.jpg" ,  

},
{
    id: 155,
    name: " Back Patio Day View  ", 
    category: "Luna", 
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Luna6.jpg" ,  

},

{
    id: 156,
    name: " Back Patio Dinning area   ", 
    category: "Luna", 
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Luna7.jpg" ,  

},
{
    id: 157,
    name: " Back Patio Dinning area side view    ", 
    category: "Luna", 
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Luna8.jpg" ,  

},
{
    id: 158,
    name: " Bedroom 1   ", 
    category: "Luna", 
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Luna9.jpg" ,  

},
{
    id: 159,
    name: " Bedroom 1 with stone wall   ", 
    category: "Luna", 
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Luna10.jpg",  

},
];

export default VillaLuna;