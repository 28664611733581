
let Pershing92 = [

    {
        id: 453,
        name: "92 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing921.PNG",
      },
     
      
      {
        id: 458,
        name: "92 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing923.PNG",
      },
      
      {
        id: 460,
        name: "92 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing925.PNG",
      },
  
      {
        id: 462,
        name: "92 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing927.PNG",
      },
      {
        id: 463,
        name: "92 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing929.PNG",
      },
    

]

export default Pershing92 ;