

let Bentley = [

    {
        id: 257,
        name: "Bentley 1  ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley1.jpg",
      },
      {
        id: 258,
        name: "Bentley 2 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley2.jpg",
      },
    
      {
        id: 259,
        name: "Bentley 3 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley3.jpg",
      },
      {
        id: 260,
        name: "Bentley 4 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley4.jpg",
      },
      {
        id: 261,
        name: "Bentley 5 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley5.jpg",
      },
      {
        id: 262,
        name: "Bentley 5 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley6.jpg",
      },
      {
        id: 263,
        name: "Bentley 5 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley7.jpg",
      },
      {
        id: 264,
        name: "Bentley 5 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley8.jpg",
      },
      {
        id: 265,
        name: "Bentley 5 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley9.jpg",
      },
      {
        id: 266,
        name: "Bentley 5 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley10.jpg",
      },
      {
        id: 267,
        name: "Bentley 5 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley11.jpg",
      },
      {
        id: 268,
        name: "Bentley 5 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley12.jpg",
      },
      {
        id: 269,
        name: "Bentley 5 ",
        category: "Hyde",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Bentley13.jpg",
      },


];

export default Bentley;