
let Rodman = [
  // {
  //   id: 364,
  //   name: "110 ",
  //   category: "Yacht",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman3.jpg",
  // },
  // {
  //   id: 365,
  //   name: "110 ",
  //   category: "Yacht",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman4.jpg",
  // },
  {
    id: 366,
    name: "110 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman5.jpg",
  },
  // {
  //   id: 372,
  //   name: "110 ",
  //   category: "Yacht",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman11.mp4",
  //   isVideo: true,
  // },

{
    id: 362,
    name: "110 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman1.jpg",
  },
  {
    id: 363,
    name: "110 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman2.jpg",
  },
  
 
 
  {
    id: 367,
    name: "110 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman6.jpg",
  },
  {
    id: 368,
    name: "110 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman7.jpg",
  },
  {
    id: 369,
    name: "110 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman8.jpg",
  },
  {
    id: 370,
    name: "110 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman9.jpg",
  },
  {
    id: 371,
    name: "110 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/rodman10.jpg",
  },
  

];
export default Rodman;