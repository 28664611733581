

let Azimut98 = [
  {
    id: 314,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut1.jpg",
  },
  // {
  //   id: 315,
  //   name: "88 1 ",
  //   category: "Yacht",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut2.jpg",
  // },
  {
    id: 316,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut3.jpg",
  },
  // {
  //   id: 317,
  //   name: "88 1 ",
  //   category: "Yacht",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut4.jpg",
  // },
  {
    id: 318,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut5.jpg",
  },
  {
    id: 319,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut6.jpg",
  },
  {
    id: 320,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut7.jpg",
  },
  {
    id: 321,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut8.jpg",
  },
  {
    id: 322,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut9.jpg",
  },
  {
    id: 323,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut10.jpg",
  },
  {
    id: 324,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut10.jpg",
  },
  {
    id: 325,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut11.jpg",
  },
  {
    id: 326,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut12.jpg",
  },
  {
    id: 327,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut13.jpg",
  },
  {
    id: 328,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut14.png",
  },
  {
    id: 329,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut15.png",
  },
  {
    id: 330,
    name: "88 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/98Azimut16.png",
  },
];

export default Azimut98;