
let HydeBeach = [

// {
//     id: 246,
//     name: "Video",
//     category: "Hyde",
//     image: "https://globalasset1.s3.us-east-2.amazonaws.com/HydeBeach.mp4",
//    isVideo: true,  // Add a flag to distinguish between video and image
//   },
  {
    id: 247,
    name: "Float",
    category: "Hyde",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/hydebeach1.jpg",
  },
  {
    id: 248,
    name: "Girls on bed ",
    category: "Hyde",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/hydebeach2.jpg",
  },
  {
    id: 249,
    name: "Girls ",
    category: "Hyde",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/hydebeach3.jpg",
  },
  {
    id: 250,
    name: "Crowd in pool ",
    category: "Hyde",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/hydebeach4.jpg",
  },
  {
    id: 251,
    name: "Girls in hats ",
    category: "Hyde",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/hydebeach5.jpg",
  },
  {
    id: 252,
    name: "Girls in hats ",
    category: "Hyde",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/hydebeach6.jpg",
  },
  {
    id: 253,
    name: "Girl in pool  ",
    category: "Hyde",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/hydebeach7.jpg",
  },
  {
    id: 254,
    name: "Girl in pool  ",
    category: "Hyde",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/hydebeach8.jpg",
  },
  {
    id: 255,
    name: "crowds in pool  ",
    category: "Hyde",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/hydebeach9.jpg",
  },
  {
    id: 256,
    name: "Girl smiling  ",
    category: "Hyde",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/hydebeach10.jpg",
  },
];
 
export default HydeBeach