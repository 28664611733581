let kiki = [
  // {
  //   id: 290,
  //   name: "Kiki video",
  //   category: "Kiki",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/kikivid.MP4",
  //   isVideo: true, // Add a flag to distinguish between video and image
  // },
  {
    id: 296,
    name: "Girls on a boat",
    category: "Kiki",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kikigirls10.jpeg",
  },
  {
    id: 230,
    name: "Seafood",
    category: "Kiki",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kikifood2.jpeg",
  },
  {
    id: 297,
    name: "Girls with hats",
    category: "Kiki",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kikigirl8.jpeg",
  },
  {
    id: 230,
    name: "Fish and wine",
    category: "Kiki",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kikifood1.jpeg",
  },
  {
    id: 298,
    name: "Girls with hats",
    category: "Kiki",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kikigirl6.jpeg",
  },
  {
    id: 299,
    name: "Girls with white dress",
    category: "Kiki",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kikigirl5.jpeg",
  },
  {
    id: 231,
    name: "Girl on bar",
    category: "Kiki",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kikicrowd2.jpeg",
  },
  {
    id: 232,
    name: "Girls on bar",
    category: "Kiki",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kikicrowd.jpeg",
  },
  // {
  //   id: 235,
  //   name: "Bottle service 3",
  //   category: "Kiki",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kiki62.mp4",
  //   isVideo: true,
  // },
  // {
  //   id: 233,
  //   name: "Bottle service 1",
  //   category: "Kiki",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kiki72.mp4",
  //   isVideo: true,
  // },
  // {
  //   id: 234,
  //   name: "Bottle service 2",
  //   category: "Kiki",
  //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/Kiki22.mp4",
  //   isVideo: true,
  // },
 
];

export default kiki;

