
let Pershing = [
    {
        id: 396,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing1.png",
      },
      {
        id: 397,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing2.png" ,
      },
      {
        id: 398,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing3.png",
      },
      {
        id: 399,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing4.png",
      },
      {
        id: 400,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing5.png",
      },
      {
        id: 401,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing6.png",
      },
      {
        id: 402,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing7.png",
      },
      {
        id: 403,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing8.png",
      },
      {
        id: 404,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing9.png",
      },
      {
        id: 405,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing10.png",
      },
      {
        id: 406,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing11.png",
      },
      {
        id: 407,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing12.png",
      },
      {
        id: 408,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing13.png",
      },
      {
        id: 409,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing14.png",
      },
      {
        id: 410,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing15.png",
      },
      {
        id: 411,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing16.png",
      },
      {
        id: 412,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing17.png",
      },
      {
        id: 413,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing18.png",
      },
      {
        id: 414,
        name: "70 ",
        category: "Yacht",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing19.png",
      },
];

export default Pershing;