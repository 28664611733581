
let SunSeeker = [

{
    id: 331,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun1.jpeg",
  },
  {
    id: 332,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun2.jpeg",
  },

  {
    id: 333,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun3.jpeg",
  },
  {
    id: 334,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun4.jpeg",
  },
  {
    id: 335,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun5.jpeg",
  },
  {
    id: 336,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun6.jpeg",
  },
  {
    id: 337,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun7.jpeg",
  },
  {
    id: 338,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun8.jpeg",
  },
  {
    id: 339,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun9.jpeg",
  },
  {
    id: 340,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun10.jpeg",
  },
  {
    id: 341,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun11.jpeg",
  },

  {
    id: 342,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun12.jpeg",
  },
  {
    id: 343,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun13.jpeg",
  },
  {
    id: 344,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun14.jpeg",
  },
  {
    id: 345,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun15.jpeg",
  },
 
  {
    id: 347,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun17.jpeg",
  },
  {
    id: 348,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun18.jpeg",
  },
  {
    id: 349,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun19.jpeg",
  },
  {
    id: 350,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun20.jpeg",
  },
  {
    id: 351,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun21.jpeg",
  },
  {
    id: 352,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun22.jpeg",
  },
  {
    id: 353,
    name: "102 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/sun23.jpeg",
  },

];
export default SunSeeker