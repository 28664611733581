
let Marion = [

    {
        id: 291,
        name: " Marion with bottle  ",          
        category: "Marion", 
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/mariongirls1.JPG", 
    
    
    },
    {
        id: 292,
        name: " Marion girls with bottle  ",          
        category: "Marion", 
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/mariongirls2.JPG", 
    
    
    },
    {
        id: 293,
        name: " Marion lamb  ",          
        category: "Marion", 
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/marionlamb.JPG", 
    
    
    },
  
    
    
];

export default Marion;