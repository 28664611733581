


let Liv = [
    {
        id: 505,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/liv1.jpg",
      },
      // {
      //   id: 512,
      //   name: "liv ",
      //   category: "club",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/liv10.mp4",
      //   isVideo : true,
      // },
      {
        id: 508,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/liv4.jpg",
      },
      {
        id: 506,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/liv2.jpg",
      },
    
      
      {
        id: 508,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/liv5.jpg",
      },
      {
        id: 508,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/liv6.jpg",
      },
      {
        id: 509,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/liv7.jpg",
      },
      {
        id: 510,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/liv8.jpg",
      },
      // {
      //   id: 511,
      //   name: "liv ",
      //   category: "club",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/liv9.mp4",
      //   isVideo: true
      // },
      
];
export default Liv