







let data_product = [
    
    {
        id: 1,
        name: " ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/elleven11.jpg",
        link: "https://prestigeglobalconcierge.com/nightlifes",
        },
    {
        id:2,
        name: "",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/gsideview.jpg",
        link: "https://prestigeglobalconcierge.com/exotics",
    },
    {
        id:3,
        name: "",
        category: "blockchain",
        image:  "https://globalasset1.s3.us-east-2.amazonaws.com/Granadahouse1.jpg",
        link: "https://prestigeglobalconcierge.com/mansions"
    },
    {
        id:4,
        name: "",
        category: "blockchain",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/pershing923.PNG",
        link: "https://prestigeglobalconcierge.com/yacht"
    },

    {
        id: 539,
        name: "",
        category: "food",
        image:"https://globalasset1.s3.us-east-2.amazonaws.com/baga8.jpg" ,
        link: "https://prestigeglobalconcierge.com/restaurant"  
     },

];
export default data_product;