

let Strawberry = [
    {
        id: 516,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/strawberry4.jpeg",
        
      },
   
      //  {
      //   id: 519,
      //   name: "liv ",
      //   category: "club",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/strawberry7.mp4",
      //   isVideo: true,
        
      // },
      {
        id: 517,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/strawberry5.jpeg",
        
      },
      {
        id: 515,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/strawberry3.jpg",
        
      },
      
      {
        id: 518,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/strawberry6.jpg",
        
      },
      {
        id: 513,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/strawberry1.jpg",
        
      },
      {
        id: 514,
        name: "liv ",
        category: "club",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/strawberry2.jpg",
        
      },
     
];

export default Strawberry