

let Gwagon = [


{
    id: 283,
    name: "G-wagon1 ",
    category: "wagon",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/gsideview.jpg",
  },

  {
    id: 284,
    name: "G-wagon2 ",
    category: "wagon",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/gbackview.jpg",
  },
  {
    id: 285,
    name: "G-wagon3 ",
    category: "wagon",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/ginside2.jpg",
  },
  {
    id: 286,
    name: "G-wagon4 ",
    category: "wagon",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/Ginside.jpg",
  },

];

export default Gwagon;