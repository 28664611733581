
let Nirvina = [
{
    id: 354,
    name: "120 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/nirvina1.WEBP",
  },
  {
    id: 354,
    name: "120 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/nirvina2.WEBP",
  },
  {
    id: 355,
    name: "120 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/nirvina3.WEBP",
  },
  {
    id: 356,
    name: "120 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/nirvina4.WEBP",
  },
  {
    id: 357,
    name: "120 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/nirvina5.WEBP",
  },
  {
    id: 358,
    name: "120 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/nirvina6.WEBP",
  },
  {
    id: 359,
    name: "120 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/nirvina7.WEBP",
  },
  {
    id: 360,
    name: "120 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/nirvina8.WEBP",
  },
  {
    id: 361,
    name: "120 1 ",
    category: "Yacht",
    image: "https://globalasset1.s3.us-east-2.amazonaws.com/nirvina9.WEBP",
  },
];
export default Nirvina