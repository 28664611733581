
let Range = [
 
    {
        id: 272,
        name: "Range 1 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range1.jpg",
      },
      {
        id: 273,
        name: "Range 2 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range2.jpg",
      },
    
      {
        id: 274,
        name: "Range 3 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range3.jpg",
      },
      {
        id: 275,
        name: "Range 3 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range4.jpg",
      },
      {
        id: 276,
        name: "Range 4 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range5.jpg",
      },
      {
        id: 277,
        name: "Range 4 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range6.jpg",
      },
      {
        id: 278,
        name: "Range 4 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range7.jpg",
      },
      {
        id: 279,
        name: "Range 4 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range8.jpg",
      },
      {
        id: 280,
        name: "Range 4 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range9.jpg",
      },
    
      {
        id: 281,
        name: "Range 4 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range10.jpg",
      },
      {
        id: 282,
        name: "Range 4 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range11.jpg",
      },
    
      {
        id: 283,
        name: "Range 4 ",
        category: "Range",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/Range12.jpg",
      },



];
 
export default Range;