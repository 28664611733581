

let Bagattelle = [
    {
        id: 532,
        name: "baga ",
        category: "food",
        image:"https://globalasset1.s3.us-east-2.amazonaws.com/baga1.jpg" ,
        
      },

    
      {
        id: 539,
        name: "baga ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/baga8.jpg",
        
      },
      {
        id: 537,
        name: "baga ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/baga6.jpg",
        
      },
   
     
      {
        id: 534,
        name: "baga ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/baga3.jpg",
        
      },
      // {
      //   id: 538,
      //   name: "baga ",
      //   category: "food",
      //   image: "https://globalasset1.s3.us-east-2.amazonaws.com/baga7.mp4",
      //   isVideo: true,
        
      // },
      {
        id: 535,
        name: "baga ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/baga4.jpeg",
        
      },
      {
        id: 536,
        name: "baga ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/baga5.jpg",
        
      },
      {
        id: 533,
        name: "baga ",
        category: "food",
        image: "https://globalasset1.s3.us-east-2.amazonaws.com/baga2.jpg",
        
      },
   
  
    
    
    
]

export default Bagattelle